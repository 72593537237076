<template>
<div>
       <label class="text-title-3">{{title}}</label>
       <!-- </div> -->
        <v-text-field   
        v-bind="$attrs"
        v-on="inputListeners"
        clearable
        class="text-h5"
        height="40"
        ></v-text-field>
</div>
</template>

<script>
export default {
  props: ['title'],
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }}
}
</script>

<style lang="scss" scoped>
::v-deep .v-input input {
    max-height: 50px !important;
}
</style>