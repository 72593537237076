<template>

        <v-dialog
        ref="dialog"
        v-model="menu"
        :return-value.sync="value"
        persistent
        width="290px"
      >

        <template v-slot:activator="{ on, attrs }">
    <label class="text-title-3">{{title}} <span v-if="$attrs.required" class="error--text">*</span>  
          <v-text-field
            v-bind:value="value"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            :dense="$attrs.dense"
            v-on="on"
            @change="$emit('change',$event)"
          ></v-text-field>
            </label>
        </template>
        <v-date-picker
          v-bind:value="value"
          @change="$emit('change',$event)"
          scrollable
          :locale='locale'
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(value)"
          >
            OK
          </v-btn>
        </v-date-picker>
        </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    title: {
      type: String,
      default: 'Picker in dialog'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array
    },
    locale: {
      type: String,
      default: 'th-th'
    }
  },
  data () {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false
    }
  },
  methods: {
    save (dat) {
      this.$emit('change', dat)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-text-field--outlined ::v-deep fieldset {
  border-color: #DBDCE7;
}
</style>
