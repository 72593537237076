<template>
  <v-dialog
        ref="dialog"
        v-model="modal2"
        :return-value.sync="localTime"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
              <label class="text-title-3">{{title}} <span v-if="$attrs.required" class="error--text">*</span>   
          <v-text-field
            v-model="localTime"
            append-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
           </label>
        </template>
        <v-time-picker
          v-if="modal2"
          v-model="localTime"
          full-width
          format="24hr"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="onChange()"
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>
</template>

<script>
export default {
    model: {
    prop: 'time',
    event: 'change'
  },
  props: {time: String,title: String},
  data() {
        return {
            modal2: false,
            localTime: this.time
        }
    },
methods: {
    onChange() {
        this.$refs.dialog.save(this.localTime)
        this.$emit('change',this.localTime)
    }
},
}
</script>

<style>

</style>